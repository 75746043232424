.layout.newsletter {
    position: relative;
    text-align: center;
    padding: 80px 0;
    margin-bottom: 100px;
    @include bg-gradient();
    h2, p {
        color: #ffffff;
    }
    form {
        display: flex;
    }
    .input-wrapper {
        &:first-of-type {
            width: 100%;
            input {
                border-radius: 3px 0px 0px 3px;
            }
        }
    }
    input[type="submit"] {
        border-radius: 0px 3px 3px 0px;
    }
    input {
        height: 40px;
    }
}
