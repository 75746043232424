.header {
	padding: 40px 0;
	background: #ffffff;
	@media screen and (max-width: 1024px) {
		padding: 20px 0;
	}
	@media screen and (max-width: 680px) {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		background: rgba(100, 100, 100, 0);
		z-index: 99999;
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			box-shadow: 0px 1px 30px #8e8e8e;
			@include bg-gradient();
			opacity: 0.9;
			transition: all 300ms ease-in-out;
		}
	}

	&.bg-active {
		@media screen and (max-width: 680px) {
			&:before {
				opacity: 0.9;
			}
		}
	}

	.wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.logo {
		position: relative;
		width: 180px;
		@media screen and (max-width: 1024px) {
			width: 180px;
		}
		@media screen and (max-width: 680px) {
			display: none;
		}
		&.mobile {
			display: none;
			@media screen and (max-width: 680px) {
				display: block;
				width: 120px;
			}
		}

		img {
			width: 100%;
		}
	}

	.nav {
		@media screen and (max-width: 1024px) {
			position: fixed;
			top: 0;
			right: 0;
			width: 100%;
			max-width: 320px;
			height: 100vh;
			transform: translateX(100%);
			@include bg-gradient();
			transition: all 300ms ease-in-out;
			z-index: 9999;
			&.is-active {
				transform: translateX(0%);
			}
			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-image: url('../img/mobile-menu-bg.png');
				background-size: cover;
				background-position: left;
				background-repeat: no-repeat;
				z-index: 0;
				opacity: 0.10;
			}
		}
	}

	.main-menu {
		display: flex;
		align-items: center;
		@media screen and (max-width: 1024px) {
			display: block;
			position: relative;
			z-index: 9999;
		}
	}

	.mobile-menu-logo {
		display: none;
		@media screen and (max-width: 1024px) {
			display: block;
			max-width: 120px;
			margin: 20px 0 20px 20px;
		}
	}

	.hamburger {
		display: none;
		position: fixed;
		top: 8px;
		right: 8px;
		background-color: transparent;
		outline: none;
		z-index: 99999;
		@media screen and (max-width: 1024px) {
			display: block;
		}
		@media screen and (max-width: 680px) {
			.hamburger-inner,
			.hamburger-inner:after,
			.hamburger-inner:before {
				background-color: #ffffff;
			}
		}
		&.is-active {
			.hamburger-inner {
				background-color: transparent;
			}
			.hamburger-inner:after,
			.hamburger-inner:before {
				background-color: #ffffff;;
			}
		}
	}

	.menu-item {
		&+.menu-item {
			display: inherit;
			margin-left: 40px;
			@media screen and (max-width: 1024px) {
				margin-left: 0;
			}
		}

		a {
			// border-bottom: 1px solid transparent;
			&:hover {
				// border-bottom: 1px solid $orange;
				transition: all 300ms ease-in-out;
				&:after {
					width: 100%;
					background-color: $orange;
				}
			}
			&:after {
				content: '';
				display: block;
				width: 0%;
				height: 1px;
				background-color: $blue;
				transition: all 300ms ease-in-out;
			}
			@media screen and (max-width: 1024px) {
				display: block;
				font-family: $heading-font-family;
			    font-weight: $heading-font-weight;
				font-size: 1em;
				padding: 5px 20px 5px 40px;
				text-transform: uppercase;
				color: #ffffff;
				transition: all 300ms ease-in-out;
				&:hover {
					color: #ffffff;
					background-color: $orange;
				}
			}
		}

		&:last-of-type a,
		.fas {
			margin-right: 2px;
			color: $orange;
			@media screen and (max-width: 1024px) {
				color: #ffffff;
			}
		}
	}

	.mobile-social {
		display: none;
		justify-content: center;
		position: absolute;
		bottom: 20px;
		left: 0;
		width: 100%;
		@media screen and (max-width: 1024px) {
			display: flex;
		}
        li {
            &+li {
                margin-left: 10px;
            }
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            color: #ffffff;
            background-color: transparent;
			border: 1px solid #ffffff;
            border-radius: 50%;
			transition: all 300ms ease-in-out;
			&:hover {
				background-color: $blue;
				border-color: $blue;
			}
        }
    }

	a {
		font-weight: 500;
		text-decoration: none;
	}
}
