html {
	background-color: $viewport-background-color;
	box-sizing: border-box;
}

*,
*::after,
*::before {
	box-sizing: inherit;
}

body,
html {
	height: 100%;
}

body {
	margin: 0;
}

.layout {
	margin-bottom: 200px;
	&:first-of-type {
		&.hero {
			.row {
				@media screen and (max-width: 680px) {
					padding-top: 40px;
				}
			}
		}
	}
}

.wrapper {
	max-width: 1200px;
	padding: 0 40px;
	margin: auto;
	@media screen and (max-width: 420px) {
		padding: 0 20px;
	}
}

.intro {
    position: relative;
    max-width: 800px;
    margin: auto;
    margin-bottom: 80px;
    text-align: center;
}

.video-wrapper {
    position: relative;
    height: 0;
    margin: auto;
    margin-top: 60px;
    padding-bottom: 56.25%;
    overflow: hidden;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.orange {
    color: $orange;
}
