.footer {
    padding-bottom: 100px;
    .row {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 680px) {
            flex-direction: column;
        }
    }
    .col {
        @media screen and (max-width: 680px) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            margin-bottom: 60px;
        }
    }
    .menus,
    .social {
        display: flex;
    }
    .menus {
        .menu {
            &+.menu {
                margin-left: 20px;
                @media screen and (max-width: 680px) {
                    margin-left: 0;
                }
            }
        }
    }
    .menu-item {
        margin-bottom: 10px;
    }
    .logo {
        display: block;
        max-width: 200px;
        margin-bottom: 20px;
    }
    .social {
        justify-content: center;
        li {
            &+li {
                margin-left: 10px;
            }
        }
        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 35px;
            height: 35px;
            color: $blue;
            background-color: transparent;
            border: 1px solid $blue;
            border-radius: 50%;
            transition: all 300ms ease-in-out;
            &:hover {
                color: #ffffff;
                background-color: $blue;
            }
        }
    }
    a {
        text-decoration: none;
    }
}
