html {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: 18px;
  font-weight: $base-font-weight;
  line-height: $base-line-height;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-family: $heading-font-family;
    font-size: modular-scale(1);
    font-weight: $heading-font-weight;
    line-height: $heading-line-height;
    margin: 0 0 $small-spacing;
    color: $blue;
}

h1, .h1 {
    font-size: 3em;
    @media screen and (max-width: 900px) {
        font-size: 2.5em;
    }
}

h2, .h2 {
    font-size: 2.75em;
    @media screen and (max-width: 900px) {
        font-size: 2.25em;
    }
}

h3, .h3 {
    font-size: 2.5em;
    @media screen and (max-width: 900px) {
        font-size: 2em;
    }
}

h4, .h4 {
    font-size: 2.25em;
    @media screen and (max-width: 900px) {
        font-size: 1.75em;
    }
}

h5, .h5 {
    font-size: 2em;
    @media screen and (max-width: 900px) {
        font-size: 1.5em;
    }
}

h6, .h6 {
    font-size: 1.25em;
    @media screen and (max-width: 900px) {
        font-size: 1.25em;
    }
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $blue;
  text-decoration-skip: ink;
  transition: color $base-duration $base-timing;

  &:hover {
    // color: shade($action-color, 25%);
    color: $action-color;
  }

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}
