.layout.basic-content {
    .wrapper {
        max-width: 800px;
        margin: auto;
    }
    ul, ol {
        padding-left: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }
    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    p {
        margin-bottom: 40px;
    }
}
