.layout.cta {

    &.align-left {
        .row {
            flex-direction: row;
            @media screen and (max-width: 768px) {
    			flex-direction: column;
    		}
        }
    }

    &.align-right {
        .row {
            flex-direction: row-reverse;
            @media screen and (max-width: 768px) {
    			flex-direction: column;
    		}
        }
    }

	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
        @media screen and (max-width: 768px) {
			flex-direction: column;
		}
	}

	.col {
		width: 50%;
        @media screen and (max-width: 768px) {
			width: 100%;
            text-align: center;
		}
	}

	.content {
		padding: 40px;
        @media screen and (max-width: 680px) {
			padding: 0;
		}
	}

    .image {
        @media screen and (max-width: 768px) {
            max-width: 320px;
        }
        @media screen and (max-width: 680px) {
			margin-bottom: 40px;
		}
    }

    .buttons {
        @media screen and (max-width: 768px) {
            text-align: center;
		}
    }

    .btn {
        @media screen and (max-width: 768px) {
            margin: auto;
		}
    }
}
