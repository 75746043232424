.option.cookie-notice {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    color: #ffffff;
    transform: translate3d(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.75);
    transition: all $base-duration $base-timing;
    z-index: 9999;
    &.move {
        transform: translate3d(0, 100%, 0);
    }
    &.hide {
        display: none;
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
    }
}
