.layout.listing {
    .slick-logos,
    .slick-track {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }
    img {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
        transition: all 300ms ease-in-out;
        &:hover {
            filter: none;
            -webkit-filter: grayscale(0%);
        }
    }
    .job-listing {
        max-width: 800px;
        margin: auto;
        text-align: center;
        .job {
            &+.job {
                margin-top: 40px;
                padding-top: 40px;
                border-top: 1px solid $dark-gray;
            }
        }
        .title {
            font-size: 1.5em;
            color: $orange;
        }
        .btn {
            margin: auto;
        }
    }
}
