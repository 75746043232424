.option.notification {
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    color: #ffffff;
    transform: translate3d(0, 0, 0);
    background-color: #000000;
    transition: all $base-duration $base-timing;
    z-index: 9999;
    &.hide {
        display: none;
    }
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        background: transparent;
    }
}
