.layout.cards {
    margin-bottom: 60px;
    &.large {
        .row {
            justify-content: flex-start;
        }
        .card {
            max-width: 500px;
            padding: 0;
            text-align: left;
            box-shadow: none;
        }
        .image {
            margin: 0;
            margin-bottom: 40px;
        }
    }
    .row {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
    .card {
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        padding: 40px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 40px;
        text-align: center;
        box-shadow: 0 10px 20px #ababab;
        @media screen and (max-width: 420px) {
            padding: 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 90px;
        margin: auto;
        margin-bottom: 20px;
        img {
            width: auto;
            height: auto;
            max-width: none;
        }
    }
    h2 {
        font-size: 1.75em;
    }
    p {
        font-size: .875em;
    }
}
