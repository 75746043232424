.layout.pricing {
    .row {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .pricing-option {
        position: relative;
        max-width: 350px;
        padding: 40px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 40px;
        text-align: center;
        border: 1px solid $blue;
        background: transparent;
        background-color: transparent;
        transition: all 300ms ease-in-out;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include bg-gradient();
            z-index: 0;
            transition: all 300ms ease-in-out;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
            * {
                color: #ffffff;
            }
            .btn {
                border: 1px solid #ffffff;
                background-color: $orange;
                &:before,
                &:after {
                    background-color: $orange;
                }
            }
        }
    }
    .content {
        position: relative;
        z-index: 1;
    }
    .title {
        font-size: 2em;
        margin-bottom: 10px;
    }
    .price {
        margin-bottom: 10px;
        span {
            font-size: 0.5em;
        }
    }
    .detail {
        margin-bottom: 40px;
    }
    ul {
        margin-bottom: 60px;
        li {
            padding: 10px 0;
            border-bottom: 1px solid $blue;
        }
    }
    .buttons {
        justify-content: center;
    }
}
