$_button-background-color: $action-color;
$_button-background-color-hover: shade($action-color, 20%);

#{$all-buttons} {
  appearance: none;
  background-color: $blue;
  border: 0;
  border-radius: $base-border-radius;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  font-weight: 800;
  line-height: 1;
  padding: $small-spacing $base-spacing;
  text-align: center;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    background-color: $_button-background-color-hover;
    color: contrast-switch($_button-background-color-hover);
  }

  &:focus {
    outline: $focus-outline;
    outline-offset: $focus-outline-offset;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $_button-background-color;
    }
  }
}

.buttons {
    display: flex;
    margin-top: 40px;
    @media screen and (max-width: 680px) {
        text-align: center;
    }
}

.btn {
    display: block;
    width: 160px;
    max-width: 100%;
    font-size: 0.875em;
    font-weight: 800;
    color: #000000;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: all $base-duration $base-timing;
    @media screen and (max-width: 680px) {
        margin: auto;
    }
    &:before,
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #000000;
        transition: all $base-duration $base-timing;
    }
    &:before {
        margin-bottom: 5px;
    }
    &:after {
        margin-top: 5px;
    }
    &.blue {
        color: $blue;
        &:before,
        &:after {
            background-color: $blue;
        }
        &:hover {
            color: #ffffff;
            background-color: $blue;
        }
    }
    &.orange {
        color: #ffffff;
        &:before,
        &:after {
            background-color: #ffffff;
        }
        &:hover {
            color: #ffffff;
            background-color: $orange;
        }
    }
    &:hover {
        background-color: $orange;
        &:before,
        &:after {
            width: 0;
        }
    }
    &+.btn {
        margin-left: 20px;
    }
}
