.layout.tables {
    /* Tables
    ================================== */
    .Rtable {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 3em 0;
      padding: 0;
    }
    .Rtable-cell {
      box-sizing: border-box;
      flex-grow: 1;
      width: 100%;  // Default to full width
      padding: 0.8em 1.2em;
      overflow: hidden; // Or flex might break
      list-style: none;
      border: solid 1px #000000;
      // background-color: $green;
      > h1, > h2, > h3, > h4, > h5, > h6 { margin: 0; }
    }

    .heading {
        font-size: 1.25em;
    }

    /* Table column sizing
    ================================== */
    .Rtable--2cols > .Rtable-cell  { width: 50%; }
    .Rtable--3cols > .Rtable-cell  { width: 33.33%; }
    .Rtable--4cols > .Rtable-cell  { width: 25%; }
    .Rtable--5cols > .Rtable-cell  { width: 20%; }
    .Rtable--6cols > .Rtable-cell  { width: 16.6%; }


    $breakpoint: 500px;

    /* Responsive
    ==================================== */
    @media all and (max-width: $breakpoint) {
      .Rtable--collapse  {
        display: block;
        > .Rtable-cell { width: 100% !important; }
        > .Rtable-cell--foot { margin-bottom: 1em; }
      }
    }

    // Non-Flex modernizer fallback
    .no-flexbox .Rtable {
      display: block;
      > .Rtable-cell { width: 100%; }
      > .Rtable-cell--foot { margin-bottom: 1em; }
    }
}
