.layout.slider {
    margin-bottom: 400px;
    padding: 100px 0 0 0;
    @include bg-gradient();
    .intro {
        * {
            color: #ffffff;
        }
    }
    .wrapper {
        @media screen and (max-width: 768px) {
            padding: 0;
        }
    }
    .slick {
        display: flex;
        justify-content: space-around;
        position: relative;
        top: 320px;
        margin-top: -240px;
    }
    .slide {
        padding: 60px 30px;
        background-color: #ffffff;
        box-shadow: 0px 0px 40px $dark-gray;
        transform: translateY(0px) scale3d(1, 1, 1);
        transition: all 300ms ease-in-out;
        backface-visibility: hidden;
    }
    .slick-current.slick-center {
        position: relative;
        transform: translateY(-100px) scale3d(1.1, 1.1, 1.1);
        z-index: 8888;
        backface-visibility: hidden;
    }
    .slick-track {
        padding: 100px 0;
    }
    .image {
        margin-bottom: 20px;
        text-align: center;
        img {
            $slider_img: 120px;
            width: $slider_img;
            height: $slider_img;
            border-radius: 50%;
            margin: auto;
        }
    }
    .title,
    .position {
        font-size: 1em;
        text-align: center;
        color: $orange;
    }
    .title {
        margin-bottom: 0px;
        text-transform: uppercase;
    }
}
