.layout.hero {
	margin-top: 20px;
	@media screen and (max-width: 680px) {
		margin-top: 0;
	}

	&.has-bg {
		* {
			color: #ffffff;
		}
		.wrapper {
			padding: 0 80px;
			@media screen and (max-width: 680px) {
				padding: 0 20px;
			}
		}
	}

	&.align-left {
		.row {
			flex-direction: row-reverse;
			@media screen and (max-width: 680px) {
				flex-direction: column;
			}
		}
		.content {
			padding-left: 40px;
			@media screen and (max-width: 680px) {
				padding-left: 0;
			}
		}
	}

	&.align-right {
		.row {
			flex-direction: row;
			@media screen and (max-width: 680px) {
				flex-direction: column;
			}
		}
		.content {
			padding-right: 40px;
			@media screen and (max-width: 680px) {
				padding-right: 0;
			}
		}
	}

	.wrapper {
		position: relative;
	}

	.background,
	.background-gradient {
		position: absolute;
		// top: 50%;
		// transform: translateY(-50%);
		left: 0;
		width: 100%;
		height: 100%;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		z-index: 0;
		@media screen and (max-width: 680px) {
			top: 0;
			// transform: translateY(0%);
			height: 100%;
		}
	}

	.background-gradient {
		@include bg-gradient();
	}

	.row {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 600px;
		z-index: 1;
		@media screen and (max-width: 680px) {
			flex-direction: column;
			text-align: center;
		}
	}

	.col {
		// width: 50%;
		@media screen and (max-width: 680px) {
			width: 100%;
		}
	}

	.content {
		width: 60%;
		padding-top: 60px;
		padding-bottom: 60px;
		@media screen and (max-width: 680px) {
			width: 100%;
			margin-bottom: 40px;
		}
	}

	.buttons {
		@media screen and (max-width: 680px) {
			justify-content: center;
		}
	}

	.image {
		margin-bottom: -120px;
		text-align: right;
		z-index: 99999;
		@media screen and (max-width: 680px) {
			width: 100%;
			max-width: 360px;
		}
		img {
			max-height: 100%;
			@media screen and (max-width: 680px) {
				width: 100%;
			}
		}
	}

	.name {
		margin-bottom: 0px;
	}
}
