// Breakpoints
$laptop: "max-width: 1260px";
$tablet: "max-width: 768px";
$mobile-lg: "max-width: 640px";
$mobile-sm: "max-width: 480px";

// Typography
@font-face {
    font-family: 'amelia_basicbold';
    src: url('fonts/amelia-basic-bold-webfont.woff2') format('woff2'),
         url('fonts/amelia-basic-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'amelia_basicregular';
    src: url('fonts/amelia-basic-regular-webfont.woff2') format('woff2'),
         url('fonts/amelia-basic-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

$base-font-family: 'Roboto', sans-serif;
$heading-font-family: 'amelia_basicbold', sans-serif;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Font Weight
$base-font-weight: 400;
$heading-font-weight: $base-font-weight;

// Other Sizes
$base-border-radius: 3px;
$base-spacing: 1.5em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$light-blue: #59CCF5;
$blue: #0685CF;
$orange: #FA9A2D;
$dark-gray: #ABABAB;
$medium-gray: #999;
$light-gray: #ddd;

// Font Colors
$base-font-color: $dark-gray;
$action-color: $orange;

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;

// Background Colors
$viewport-background-color: #fff;

// Focus
$focus-outline-color: transparentize($action-color, 0.4);
$focus-outline-width: 3px;
$focus-outline: $focus-outline-width solid $focus-outline-color;
$focus-outline-offset: 2px;

// Animations
$base-duration: 300ms;
$base-timing: ease-in-out;
