.layout.blog {
    padding-top: 80px;
    .intro {
        margin-bottom: 180px;
    }
    .post-listing {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .post-item {
        position: relative;
        flex-direction: column;
        width: 100%;
        max-width: 300px;
        padding: 30px;
        padding-top: 100px;
        padding-bottom: 80px;
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 200px;
        text-align: center;
        border: 1px solid $blue;
        box-shadow: 0 0 0 #ababab;
        transition: all 300ms ease-in-out;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            @include bg-gradient();
            z-index: 0;
            transition: all 300ms ease-in-out;
        }
        &:hover {
            box-shadow: 0 10px 20px #ababab;
            transform: scale(1.05);
            &:before {
                opacity: 1;
            }
            * {
                color: #ffffff;
            }
            .image {
                a {
                    background-color: rgba(250, 154, 45, 0.80);
                }
            }
            .btn {
                border: 1px solid #ffffff;
                background-color: $orange;
                &:before,
                &:after {
                    background-color: $orange;
                }
            }
        }
        .post-body {
            position: relative;
            z-index: 10;
        }
        .image {
            position: absolute;
            top: -120px;
            left: 0;
            right: 0;
            width: 200px;
            height: 200px;
            margin: auto;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border: 1px solid $blue;
            overflow: hidden;
            a {
                display: block;
                width: 100%;
                height: 100%;
                background-color: transparent;
                transition: all 300ms ease-in-out;
            }
        }
        .category {
            padding-bottom: 0px;
            margin-bottom: 20px;
            border-bottom: 1px solid $dark-gray;
            a {
                font-size: 0.865em;
                text-decoration: none;
            }
        }
        .blog-post-text {
            a {
                text-decoration: none;
            }
        }
        .sharing {
            display: flex;
            position: absolute;
            bottom: 20px;
            right: 30px;
            overflow: hidden;
            a {
                &:focus {
                    outline: none;
                }
            }
            .fas {
                margin-left: 10px;
            }
            &.active {
                .social {
                    right: 0%;
                }
            }
        }
        .social {
            position: relative;
            right: -200%;
            display: flex;
            justify-content: center;
            transition: all 300ms ease-in-out;
            li {
                &+li {
                    margin-left: 10px;
                }
            }
        }
    }
}
